import React from 'react';
  
const Refresh = () => {
  const refresh = () => window.location.reload(true);
  return (
    <div>
      <h1>
        Refresh!
      </h1>
      <div id='button'>
        <button onClick={refresh}>Refresh</button>
      </div>
    </div>
  );
};
  
export default Refresh;