import React from 'react';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route}
	from 'react-router-dom';
import './App.css';
import Home from './pages';
import Refresh from './pages/refresh';
import CheckAuth from './pages/checkauth';
import SignOut from './pages/signout';

function App() {
return (
	<div className="App">
		<Router>
		<Navbar />
		<Routes>
			<Route path='/' element={<Home />} />
			<Route path='/home' element={<Home />} />
			<Route path='/parseauth' element={<Home />} />
			<Route path='/checkauth' element={<CheckAuth />} />
			<Route path='/refresh' element={<Refresh />} />
			<Route path='/signout' element={<SignOut />} />
		</Routes>
		</Router>
	</div>
);
}

export default App;
