import React from 'react';
  
const CheckAuth = () => {
  const refresh = () => window.location.reload(true);

  return (
    <div>
      <h1>
       Check Auth! Sign in redirectly if not already.
       <div id='button'>
        <button>Do nothing</button>
       </div>
       <div id='button'>
        <button onClick={refresh}>Check Auth</button>
       </div>
      </h1>
    </div>
  );
};
  
export default CheckAuth;