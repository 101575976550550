
import React from 'react';
  
const Home = () => {
  return (
    <div>
      <h1>Welcome! Hello world!</h1>
    </div>
  );
};
  
export default Home;