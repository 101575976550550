import React from "react";
  
const SignOut = () => {
  const refresh = () => window.location.reload(true);
  return (
    <div>
      <h1>
        Sign out!
      </h1>
      <div id='button'>
        <button onClick={refresh}>Sign out</button>
      </div>
    </div>
  );
};
  
export default SignOut;